/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.prose {
  @apply prose-h1:text-amber-700 prose-h2:text-amber-600 prose-h3:text-amber-500 prose-headings:uppercase prose-headings:tracking-wide prose-h4:mb-0 prose-h3:mt-4 prose-h2:text-center prose-h3:text-center prose-h2:mt-16 prose-h2:mb-0
}

h2 {
  @apply text-amber-600
}
.stat-value {
  @apply text-amber-600 text-3xl
}
